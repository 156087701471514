<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="primary" size="sm" :to='{name: "users.permissions.bulkCreate"}' class="mr-3">
          Bulk Create
        </b-button>
        <b-button variant="primary" size="sm" v-b-modal.modal-new-permission>
          <BIconPlusCircle/> New Permission
        </b-button>
        <b-modal @shown="initForm" id="modal-new-permission" title="Create new permission" hide-footer>
          <FormTemplate v-if="form" :form="form">
              <b-form-group class="label-for-xl" label="Placeholder" label-for="inputPlaceholder">
                  <b-form-input
                      type="text" id="inputPlaceholder" class="form-control form-control-xl"
                      placeholder="enter the name"
                      v-model="form.data.placeholder"
                      :state="form.states.placeholder"
                  />
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.placeholder }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="label-for-xl" label="Description" label-for="inputDescription">
                  <b-form-textarea
                      id="inputDescription" class="form-control form-control-xl"
                      placeholder="enter the description"
                      v-model="form.data.description"
                      :state="form.states.description"
                  />
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.description }}</b-form-invalid-feedback>
              </b-form-group>
          </FormTemplate>
        </b-modal>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card class="mb-3">
        <b-card-text>
          <PermissionFilters @on-search="applyFilters"/>
        </b-card-text>
      </b-card>

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields">
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "users.permissions.permission", params: {permissionId: data.item.id}}'><BIconPencil/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
import {BIconPlusCircle,BIconPencil} from 'bootstrap-vue';
import PaginationRouter from '@/common/components/Pagination.Router';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
import {PermissionFilters} from './../../components/users';
export default {
    components: {
      BIconPlusCircle,BIconPencil,
      PageMenu,PaginationRouter,FormTemplate,
      PermissionFilters
    },
    data(){
      return {
        page: {
            title: "Permissions",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "Permissions",
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'createdAt',
              label: "Created",
              formatter: (value) => {
                return moment(value).format('DD/MM/YYYY');
              }
            },
            {
              key: 'placeholder',
              label: "Placeholder"
            },
            {
              key: 'description',
              label: "Description",
              formatter: (value) => {
                return value ? value : '-';
              }
            },
            {
              key: 'options',
              label: ""
            },
          ]
        },
        form: null
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'users.permissions',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('permissions',{
          params: Object.assign({
            perPage: 20,
            page: pageNo,
            orderBy: "placeholder",
            arrange: "asc"
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
        });
      },
      applyFilters(filters){
        this.getResults({pageNo: 1}, filters);
      },
      initForm(){
        this.form = new Form({description: "", placeholder: ""},{
          onSubmit: (form) => {
            return this.$api.post('permission',{
              data: {description: form.get('description'),placeholder: form.get('placeholder')}
            }).then((res) => {
              this.getResults({pageNo: 1},{
                orderBy: "datetime",
                arrange: "desc"
              });
              return res;
            });
          },
          onSuccess: (form) => {
            form.resetData();
          }
        });
      }
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.$emit('set-sidebar',{active: "users"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
